<template>
  <div>
    <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center">
      <div class="bg-white w-full rounded-lg shadow">
        <div
          class="py-3 flex pt-5 mt-5 flex-wrap relative items-center border-gray-200 p-6 overflow-hidden"
        >
          <div
            class="text-center w-full py-4 bg-whatsap text-white text-3xl mb-5 rounded"
          >
            <h1 class="text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 24 24"
                class="inline-block text-white fill-current"
              >
                <path
                  d="M20 9.352c0-4.852-4.75-8.352-10-8.352-5.281 0-10 3.527-10 8.352 0 1.71.615 3.39 1.705 4.695.047 1.527-.85 3.719-1.66 5.312 2.168-.391 5.252-1.258 6.648-2.115 7.698 1.877 13.307-2.842 13.307-7.892zm-13.92 2.21c-.347.275-.83.413-1.45.413-.53 0-1.001-.102-1.408-.306l.228-.902c.412.208.828.312 1.251.312.244 0 .435-.046.571-.139.262-.179.278-.539.02-.743-.125-.097-.337-.197-.641-.298-.915-.305-1.371-.781-1.371-1.43 0-.42.167-.765.503-1.036.337-.271.786-.407 1.344-.407.488 0 .913.083 1.279.249l-.248.873c-.334-.156-.684-.233-1.045-.233-.221 0-.396.043-.522.128-.25.169-.245.475-.03.655.106.089.356.205.759.347.442.156.767.35.973.581.205.231.308.518.308.861 0 .441-.175.799-.521 1.075zm5.531.334c-.076-1.901-.113-3.191-.113-3.868h-.023c-.114.509-.503 1.773-1.165 3.789h-.859c-.397-1.391-.981-3.789-.981-3.789h-.015l-.169 3.868h-1.025l.305-4.792h1.492l.845 3.306 1.024-3.306h1.509l.257 4.792h-1.082zm4.637-.314c-.348.277-.842.418-1.465.418-.506 0-.983-.089-1.439-.317l.241-.956c.4.205.811.325 1.269.325.237 0 .425-.044.555-.135.252-.169.262-.508.02-.7-.122-.095-.334-.193-.632-.294-.923-.306-1.392-.794-1.392-1.454 0-.425.174-.781.516-1.056.338-.275.796-.413 1.358-.413.49 0 .924.084 1.291.25l.02.01-.264.926c-.337-.159-.674-.246-1.061-.246-.215 0-.387.042-.509.124-.234.161-.229.444-.026.615.103.085.354.201.75.342.445.156.775.355.982.588.209.236.316.53.316.879.001.447-.178.815-.53 1.094zm6.636 7.939c-.029 1.001.558 2.435 1.088 3.479-1.419-.258-3.438-.824-4.352-1.385-3.279.798-6.064-.223-7.577-2.1.596-.086 1.178-.205 1.74-.364 1.824 1.552 3.962 1.374 6.089.855.687.422.773.507 1.665.813-.187-.816-.158-1.12-.138-1.865 1.793-2.14 1.213-4.104-.464-5.365.239-.455.439-.928.601-1.416 2.891 1.913 3.103 5.251 1.348 7.348z"
                ></path>
              </svg>
              SMS Sender
            </h1>
          </div>
          <div class="w-full">
            <div class="item bg-device bg-white shadow-md rounded-md p-3">
              <div
                class="flex items-center cursor-pointer"
                @click="faq_selectedF"
              >
                <div
                  class="bg-indigo-100 text-indigo-400 w-8 h-8 md:w-10 md:h-10 rounded-md flex items-center justify-center font-bold text-lg font-display"
                >
                  <span
                    ><i class="large font-bold material-icons align-bottom">{{
                      faq_selected ? `remove` : `add`
                    }}</i></span
                  >
                </div>
                <div class="ml-3 md:ml-4 lg:ml-6 md:text-lg text-blue-600">
                  <span class="font-bold">Status settings</span>
                </div>
              </div>
              <div
                class="max-h-0 relative overflow-hidden transition-all duration-700"
                v-bind:style="
                  faq_selected ? `max-height:  715px;overflow-y: scroll` : ``
                "
              >
                <div class="text-gray-700 py-5 space-y-3">
                  <div
                    class="w-full bg-www flex flex-wrap items-start bg-gray-100 rounded p-3"
                  >
                    <div class="w-full flex items-center justify-first mb-4">
                      <div class="w-2 h-2 bg-green-500 rounded-full mr-3"></div>
                      <span class="truncate">Shipping Status</span>
                      <div
                        class="h-px flex-1 border border-r border-dashed border-green-800 mx-3"
                      ></div>
                    </div>

                    <div
                      v-for="(item, index) in statusShippings"
                      :key="'A' + index"
                      class="md:w-1/4 w-full p-2"
                    >
                      <div
                        class="p-3 box-w rounded bg-white shadow w-full flex flex-col relative"
                      >
                        <label
                          class="inline-flex items-center absolute top-0 right-0 p-2"
                        >
                          <input
                            type="checkbox"
                            v-model="item.isActive"
                            class="form-checkbox cursor-pointer"
                          />
                        </label>
                        <span
                          class="text-center font-semibold capitalize"
                          :style="'color:' + item.status.color"
                        >
                          {{ item.status.name }}
                        </span>
                        <small
                          class="italic text-center text-xs text-gray-600 mb-6 capitalize"
                        >
                          ( Shipping {{ item.status.name }} )
                        </small>

                        <button
                          @click="editMessages(item)"
                          class="w-full border-0 py-1 text-white bg-whatsap rounded hover:bg-whatsap-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="inline-block text-white fill-current"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M20 9.352c0-4.852-4.75-8.352-10-8.352-5.281 0-10 3.527-10 8.352 0 1.71.615 3.39 1.705 4.695.047 1.527-.85 3.719-1.66 5.312 2.168-.391 5.252-1.258 6.648-2.115 7.698 1.877 13.307-2.842 13.307-7.892zm-13.92 2.21c-.347.275-.83.413-1.45.413-.53 0-1.001-.102-1.408-.306l.228-.902c.412.208.828.312 1.251.312.244 0 .435-.046.571-.139.262-.179.278-.539.02-.743-.125-.097-.337-.197-.641-.298-.915-.305-1.371-.781-1.371-1.43 0-.42.167-.765.503-1.036.337-.271.786-.407 1.344-.407.488 0 .913.083 1.279.249l-.248.873c-.334-.156-.684-.233-1.045-.233-.221 0-.396.043-.522.128-.25.169-.245.475-.03.655.106.089.356.205.759.347.442.156.767.35.973.581.205.231.308.518.308.861 0 .441-.175.799-.521 1.075zm5.531.334c-.076-1.901-.113-3.191-.113-3.868h-.023c-.114.509-.503 1.773-1.165 3.789h-.859c-.397-1.391-.981-3.789-.981-3.789h-.015l-.169 3.868h-1.025l.305-4.792h1.492l.845 3.306 1.024-3.306h1.509l.257 4.792h-1.082zm4.637-.314c-.348.277-.842.418-1.465.418-.506 0-.983-.089-1.439-.317l.241-.956c.4.205.811.325 1.269.325.237 0 .425-.044.555-.135.252-.169.262-.508.02-.7-.122-.095-.334-.193-.632-.294-.923-.306-1.392-.794-1.392-1.454 0-.425.174-.781.516-1.056.338-.275.796-.413 1.358-.413.49 0 .924.084 1.291.25l.02.01-.264.926c-.337-.159-.674-.246-1.061-.246-.215 0-.387.042-.509.124-.234.161-.229.444-.026.615.103.085.354.201.75.342.445.156.775.355.982.588.209.236.316.53.316.879.001.447-.178.815-.53 1.094zm6.636 7.939c-.029 1.001.558 2.435 1.088 3.479-1.419-.258-3.438-.824-4.352-1.385-3.279.798-6.064-.223-7.577-2.1.596-.086 1.178-.205 1.74-.364 1.824 1.552 3.962 1.374 6.089.855.687.422.773.507 1.665.813-.187-.816-.158-1.12-.138-1.865 1.793-2.14 1.213-4.104-.464-5.365.239-.455.439-.928.601-1.416 2.891 1.913 3.103 5.251 1.348 7.348z"
                            />
                          </svg>
                          Edit Message
                        </button>
                      </div>
                    </div>

                    <div class="w-full flex items-end justify-end mt-4">
                      <button
                        @click="save"
                        class="rounded flex focus:outline-none outline-none text-gray-100 px-10 w-1/7 py-2 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex items-center justify-first mt-4 mb-4">
            <div class="w-2 h-2 bg-green-500 rounded-full mr-3"></div>
            <span class="truncate">Messages Histories</span>
            <div
              class="h-px flex-1 border border-r border-dashed border-green-800 mx-3"
            ></div>
          </div>
          <div
            class="w-full bg-device flex flex-wrap items-start bg-gray-100 mb-5 rounded p-3"
          >
            <div class="flex flex-col w-full">
              <div class="flex justify-between items-center">
                <div class="flex flex-wrap items-center">
                  <div
                    v-for="(item, index) in statusMessages"
                    :key="index"
                    class="p-1"
                  >
                    <div
                      @click="refreshMessages(item)"
                      :class="
                        item === type
                          ? 'shadow-md bg-white'
                          : 'bg-gray-200 hover:bg-gray-300'
                      "
                      class="bg-device text-gray-600 px-3 py-2 rounded text-sm cursor-pointer"
                    >
                      {{ item }}
                      <span v-if="item === type" class="text-blue-600 text-xs">
                        ({{ messages.length }})
                      </span>
                    </div>
                  </div>
                </div>
                <div class="p-1">
                  <div
                    @click="refreshMessages()"
                    class="bg-device bg-white hover:bg-gray-200 text-gray-600 px-3 py-2 rounded text-sm cursor-pointer shadow"
                  >
                    <div class="flex flex-no-wrap items-center">
                      <svg
                        class="fill-current text-gray-600 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M20.944 12.979c-.489 4.509-4.306 8.021-8.944 8.021-2.698 0-5.112-1.194-6.763-3.075l1.245-1.633c1.283 1.645 3.276 2.708 5.518 2.708 3.526 0 6.444-2.624 6.923-6.021h-2.923l4-5.25 4 5.25h-3.056zm-15.864-1.979c.487-3.387 3.4-6 6.92-6 2.237 0 4.228 1.059 5.51 2.698l1.244-1.632c-1.65-1.876-4.061-3.066-6.754-3.066-4.632 0-8.443 3.501-8.941 8h-3.059l4 5.25 4-5.25h-2.92z"
                        />
                      </svg>
                      Refresh
                    </div>
                  </div>
                </div>
              </div>
              <div class="overflow-x-auto">
                <div class="p-2 px-0 align-middle inline-block min-w-full">
                  <div
                    class="shadow bg-device overflow-hidden border-b border-gray-200 rounded relative"
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                          >
                            From
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                          >
                            To Custumer
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                          >
                            Country
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                          >
                            Created At
                          </th>
                          <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Action</th>  -->
                        </tr>
                      </thead>
                      <tbody class="relative bg-white divide-y divide-gray-200">
                        <loading
                          :active.sync="loading"
                          :is-full-page="fullPage"
                        ></loading>
                        <tr v-for="(item, index) in messages" :key="index">
                          <td class="px-6 py-1 whitespace-nowrap">
                            <div class="flex flex-col">
                              <span class="font-semibold text-blue-800 text-sm">
                                RM EXPRESS
                              </span>
                            </div>
                          </td>
                          <td class="px-6 py-1 whitespace-nowrap">
                            <div class="flex flex-no-wrap items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 24 24"
                                class="fill-current text-green-500"
                              >
                                <path
                                  d="M12.068.016l-3.717 3.698 5.263 5.286h-13.614v6h13.614l-5.295 5.317 3.718 3.699 11.963-12.016z"
                                ></path>
                              </svg>
                              <span
                                class="font-medium text-green-800 text-sm pl-2"
                              >
                                {{ item.phone }}
                              </span>
                            </div>
                          </td>
                          <td class="px-6 py-1 whitespace-nowrap">
                            <img
                              v-if="idWharhouse"
                              :src="idWharhouse.flag"
                              class="w-4 h-4 rounded-full"
                            />
                          </td>
                          <td class="px-6 py-1 whitespace-nowrap">
                            <span
                              class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full"
                              :class="getClass(item.status)"
                              >{{ item.status }}</span
                            >
                          </td>
                          <td
                            class="px-6 py-1 whitespace-nowrap text-sm text-gray-600"
                          >
                            <span class="text-medium text-gray-600">{{
                              $moment(item.date).format('yyyy-MM-DD , H:mm')
                            }}</span>
                          </td>
                          <!-- <td class="px-6 py-1 whitespace-nowrap text-right text-sm font-medium">
                                      <div class="flex flex-wrap justify-left"> 
                                        <div v-if="item.status=='PENDING'" @click="UpdateMessage(item._id,'CANCELED')" class="text-red-600 hover:text-red-800 cursor-pointer p-1 flex flex-no-wrap items-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" class="text-red-600 fill-current mr-2" width="18" height="18" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z"/></svg> 
                                          Cancel
                                        </div>
                                      </div>
                                    </td> -->
                        </tr>
                        <tr v-if="messages.length == 0" class="intro-x">
                          <td
                            class="text-center padding-empty text-gray-600 py-5"
                            colspan="6"
                          >
                            No messages found
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="flex justify-between items-center">
                <div class="text-gray-600 text-xs font-medium p-1">
                  {{ paginate.currentpage }} - {{ messages.length }} /
                  {{ paginate.total }}
                </div>
                <div v-if="paginate.lastpage > 1" class="flex flex-no-wrap">
                  <div v-if="paginate.currentpage > 1" class="p-1">
                    <div
                      @click="paginatation('prev')"
                      :disabled="paginate.currentpage == 1"
                      class="bg-white hover:bg-gray-200 text-gray-600 px-3 py-2 rounded text-sm cursor-pointer shadow flex flex-no-wrap items-center"
                    >
                      <svg
                        class="fill-current text-gray-600 mr-2"
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path
                          d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm-4.828 11.5l4.608 3.763-.679.737-6.101-5 6.112-5 .666.753-4.604 3.747h11.826v1h-11.828z"
                        />
                      </svg>
                      Previous
                    </div>
                  </div>
                  <div
                    v-if="paginate.currentpage < paginate.lastpage"
                    class="p-1"
                  >
                    <div
                      @click="paginatation('next')"
                      :disabled="paginate.currentpage == paginate.lastpage"
                      class="bg-white hover:bg-gray-200 text-gray-600 px-3 py-2 rounded text-sm cursor-pointer shadow flex flex-no-wrap items-center"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        class="fill-current text-gray-600 mr-2"
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path
                          d="M12 0c-6.623 0-12 5.377-12 12s5.377 12 12 12 12-5.377 12-12-5.377-12-12-12zm0 1c-6.071 0-11 4.929-11 11s4.929 11 11 11 11-4.929 11-11-4.929-11-11-11zm4.828 11.5l-4.608 3.763.679.737 6.101-5-6.112-5-.666.753 4.604 3.747h-11.826v1h11.828z"
                        />
                      </svg>
                      Next
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Modal change status Shipping -->
    <vue-modal-2
      name="ModalWhtasap"
      :headerOptions="{ title: 'Message' }"
      :noFooter="true"
      modalSize="lg"
      @onclose="close('ModalWhtasap')"
    >
      <div class="bg-white rounded-lg">
        <div class="bg-white px-4 pt-0 pb-1">
          <div
            class="flex flex-wrap items-start border p-5 border-dotted rounded-md border-gray-500 mb-2"
          >
            <div
              class="flex w-full flex-wrap bg-blue-100 border border-solid border-blue-200 rounded p-3 mb-3"
            >
              <div class="flex items-center w-full mb-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  class="fill-current text-blue-500"
                >
                  <path
                    d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"
                  ></path>
                </svg>
                <div class="w-2"></div>
                <span class="w-full text-md text-blue-500"> Variables </span>
              </div>
              <div class="flex flex-wrap items-start w-full">
                <div class="flex flex-nowrap text-xs text-gray-700 w-full mb-1">
                  <b> [WAREHOUSE] : </b>
                  <span class="px-1"> Warehouse name</span>
                </div>
                <div class="flex flex-nowrap text-xs text-gray-700 w-full mb-1">
                  <b> [LIVREUR] : </b>
                  <span class="px-1"> Livreur phone number</span>
                </div>
                <div class="flex flex-nowrap text-xs text-gray-700 w-full mb-1">
                  <b> [CURRENCY] : </b> <span class="px-1"> Currency</span>
                </div>
                <div class="flex flex-nowrap text-xs text-gray-700 w-full mb-1">
                  <b> [ORDER_ID] : </b> <span class="px-1"> Order id</span>
                </div>
                <div
                  v-if="itemToEdit.type === 'shipping'"
                  class="flex flex-nowrap text-xs text-gray-700 w-full mb-1"
                >
                  <b> [ORDER_TRACKING] : </b>
                  <span class="px-1"> Tracking Number </span>
                </div>
                <div class="flex flex-nowrap text-xs text-gray-700 w-full mb-1">
                  <b> [ORDER_TOTAL] : </b>
                  <span class="px-1"> Order Total Price </span>
                </div>
              </div>
            </div>
            <div class="tm_whatsapp bg-www w-full flex flex-col p-2 rounded-lg">
              <div
                v-for="(item, index) in itemToEdit.messages"
                :key="'B' + index"
                class="relative flex items-start w-full"
              >
                <div
                  class="bg-white shadow-lg rounded-lg p-3 w-full flex flex-col h-full"
                  style="min-height: 120px"
                >
                  <small class="mb-2">
                    Message
                    <span v-if="item.type == 'image'"
                      >( .jpeg , .png , .jpg )</span
                    ></small
                  >
                  <div
                    v-if="item.type != 'text'"
                    class="w-full mb-1 rounded-md inline-flex border"
                  >
                    <div
                      v-if="item.type == 'image'"
                      class="ounded-md w-1/12 bg-gray-100"
                    >
                      <img
                        :src="
                          item.link
                            ? item.link
                            : 'https://img.icons8.com/color/96/000000/image.png'
                        "
                      />
                    </div>
                    <input
                      type="text"
                      v-model="item.link"
                      placeholder="link"
                      class="form-input senders w-full"
                    />
                  </div>
                  <textarea
                    placeholder="text"
                    rows="4"
                    v-model="item.content"
                    height="100%"
                    type="text"
                    class="form-textarea text-sm w-full mb-0"
                  ></textarea>
                </div>
                <!---->
                <!---->
                <!---->
                <!-- <div @click="removeItem(index)" class="absolute flex items-center justify-center right-0 p-1 pr-0  shadow-lg text-center text-white rounded-full bg-red-500  cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 w-4 h-4 mr-1 w-4 h-4 mr-1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg> </div> -->
              </div>
            </div>
          </div>
          <div
            class="p-4 px-4 flex space-x-4 md:flex border p-5 border-dotted rounded-md border-gray-500"
          >
            <a
              href="#"
              @click="close('ModalWhtasap')"
              class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm"
              >Cancel</a
            >
            <a
              href="#"
              @click="confirmChange"
              class="w-1/2 px-4 py-3 text-center text-blue-100 bg-blue-600 rounded-lg hover:bg-blue-700 hover:text-white font-bold text-sm"
              >Save</a
            >
          </div>
        </div>
      </div>
    </vue-modal-2>
    <!-- end modal change status shipping -->
  </div>
</template>

<script>
const axios = require('axios')
let sio = {}
const TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYxYTlmYmE2Nzc1YzgwMjE4MzIyZjM0NSJ9LCJpYXQiOjE2Mzg1Mjk5NTgsImV4cCI6MTY3MDA2NTk1OH0.7c4WYj5sjeZ6BsI8PatSE8rSkWtNV4YxyH4FVZYChrc'
export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
     sous_idWharhouse: { type: String},
     sous_warehouses: { type: Array},
   },
  data() {
    return {
      status: [],
      senders: [],
      statusOrders: [],
      statusShippings: [],
      limit: 50,
      starsession: {
        loading: false,
        session: '',
      },
      sessions: [],
      session: {
        loading: false,
        session: '',
      },
      type: 'ALL',
      itemToEdit: { messages: [] },
      typeMessage: null,
      messages: [],
      loading: false,
      fullPage: false,
      statusMessages: [
        'ALL',
        'PREPARED',
        'SHIPPED',
        'REFUSED',
        'CANCELED',
        'DELIVERED',
        'PAID',
      ],
      faq_selected: false,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
    }
  },
  async mounted() {
    await this.getStatus({ limit: this.limit })
    // await this.AddMessage()
    await this.getMessages()
  },

  filters: {},
  computed: {
        warhouseSelected(){
          return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
        },
   },
  watch: {
    'idWharhouse': async function(oldVal, newVal) {
            this.idWharhouse=oldVal;  
            await this.getMessages()
      },
  },
  methods: {
    faq_selectedF() {
      this.faq_selected = !this.faq_selected
    },
    getClass(status) {
      if (status === 'PENDING') return 'bg-orange-200 text-orange-800'
      else if (status === 'error' || status === 'invalid')
        return 'bg-red-200 text-red-800'
      else if (status === 'sending' || status === 'SENT')
        return 'bg-green-200 text-green-800'
      else return 'bg-yellow-200 text-yellow-800'
    },

    async UpdateMessage(id, status) {
      this.loading = true
      const BASEURL =
        'https://api-wp.storeino.world/v1/messages/update?id=' + id
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'x-auth-token': TOKEN,
        },
      }
      await axios
        .put(BASEURL, { status: status }, axiosConfig)
        .then((res) => {
          this.loading = false
          this.getMessages()
          alert('Message Upadated', 'success')
        })
        .catch((err) => {
          this.loading = false
        })
    },
    async refreshMessages(type = null) {
      const filters = { page: 1 }
      if (type) this.type = type
      if (this.type != 'ALL') filters['orderStatus'] = this.type.toLowerCase()

      await this.getMessages(filters)
    },
    async getMessages(filters = {}) {
      this.loading = true
      filters['country'] = this.idWharhouse.country
      const res = await this.$server.search('sendersHistories', filters)
      this.loading = false
      if (res.content && res.content.results) {
        this.messages = res.content.results
        this.paginate.total = res.content.total
        this.paginate.lastpage = res.content.last_page
        this.paginate.per_page = res.content.per_page
        this.paginate.currentpage = res.content.current_page
      } else {
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 }
        this.messages = []
      }
    },
    async AddMessage() {
      const BASEURL = 'https://api-wp.storeino.world/v1/wp/addMessage'
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'x-auth-token': TOKEN,
        },
      }
      let data = {
        contents: [
          {
            phone: '212655624982',
            session: 'shipsen',
            priority: 1,
            messages: [
              {
                type: 'text',
                content: {
                  text: 'Hello Moukhtar',
                },
              },
            ],
          },
        ],
      }
      await axios.put(BASEURL, data, axiosConfig)
    },
    async getStatus(filters) {
      const resSenders = await this.$server.search('senders', filters)

      if (resSenders && resSenders.content && resSenders.content.results)
        this.senders = resSenders.content.results
      else this.senders = []

      filters['type'] = 'shipping'
      const res = await this.$server.search('status', filters)

      if (res.content && res.content.results) this.status = res.content.results
      else this.status = []

      this.status.forEach((arg) => {
        var index = this.senders.findIndex(
          (x) =>
            x.status &&
            x.status.name.toLowerCase() === arg.name.toLowerCase() &&
            x.type === arg.type,
        )
        if (index === -1) {
          this.senders.push({
            name: arg.name.toLowerCase(),
            type: 'shipping',
            status: {
              name: arg.name.toLowerCase(),
              _id: arg._id,
              color: arg.color,
            },
            isActive: false,
            messages: [],
          })
        }
      })

      // this.statusOrders = this.senders.filter(function(obj) {if (obj.type === "order") return true;else return false;});
      this.statusShippings = this.senders.filter(function (obj) {
        if (obj.type === 'shipping') return true
        else return false
      })
    },
    async refresh() {
      this.getStatus({ limit: this.limit })
    },
    close(id) {
      this.$vm2.close(id)
    },
    async paginatation(direction) {
      var page = 0
      if (direction == 'next') page = ++this.paginate.currentpage
      else page = --this.paginate.currentpage
      const filters = { page: page }
      await this.getMessages(filters)
    },
    async confirmChange() {
      //Find index of specific object using findIndex method.

      var objIndex = this.senders.findIndex(
        (obj) =>
          obj.status.name == this.itemToEdit.status.name &&
          obj.type == this.itemToEdit.type,
      )
      //Update object's name property.
      this.senders[objIndex].messages = this.itemToEdit.messages

      this.$vm2.close('ModalWhtasap')
      await this.save()
    },
    editMessages(data) {
      this.itemToEdit = { messages: [] }
      this.$vm2.open('ModalWhtasap')
      this.itemToEdit = data
      if (this.itemToEdit.messages.length == 0) {
        this.itemToEdit.messages.push({
          type: 'text',
          content: 'Your Order is ' + data.status.name,
        })
      }
    },
    async save() {
      if (this.senders.length) {
        //
        //return false
        for (var i = 0; i < this.senders.length; i++) {
          if (this.senders[i]._id) {
            //
            const data = await this.$server.update('senders', this.senders[i])
          } else {
            const data = await this.$server.create('senders', this.senders[i])
          }
          if (i == this.senders.length - 1)
            await this.getStatus({ limit: this.limit })
        }
        alert('Whatssap sender updated', 'success')
      }
    },

    reformSession(list = []) {
      sio.removeAllListeners()
      let data = JSON.parse(JSON.stringify(list))

      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        data[index]['breafName'] = element.name.split('_')[0]
        data[index]['qrcode'] = ''
        data[index]['active'] = false

        // this.leaveSession(element.name.split('_')[0]);
        this.joinToSession(element.name.split('_')[0])
      }
      return data
    },
    joinToSession(sessionName) {
      let vm = this
      sio.emit('join', { token: TOKEN, session: sessionName })

      sio.on(`updateQr_${sessionName}`, function (data) {
        for (let index = 0; index < vm.sessions.length; index++) {
          if (vm.sessions[index].breafName === data.session.split('_')[0]) {
            const element = vm.sessions[index]
            vm.sessions[index]['qrcode'] = data.qrcode
          }
        }
        vm.sessions = vm.sessions
        // vm.$set(vm.session[data.session.split('_')[0]], 'qrcode', data.qrcode);
      })

      // sio.on(`status_${sessionName}`, function (data) {
      //
      //   if (data.status == 'qrReadSuccess' || data.status == 'Connected' || data.status == 'qrReadFail') {
      //     vm.$set(vm.session, data.session.split('_')[0], null);
      //   }
      //   if (data.status == 'qrReadFail') vm.getAllSession();
      // });
    },
  },
}
</script>

<style>
#vm2__cover_wrapper_ModalWhtasap .vm2_body {
  max-height: 600px;
}

.econ-des {
  vertical-align: bottom;
  font-size: 15px;
}

.max-h-0 {
  max-height: 0;
}

.img-whtasap {
  display: inline-block;
  vertical-align: initial;
}

.bg-whatsap {
  background-color: #ff7934;
}

.form-input.senders {
  border-radius: 0 0.25rem 0.25rem 0;
}

.bg-whatsap-100 {
  background-color: #197667;
}

.box-w {
  border: 2px solid #ff7934;
}

.btn_wp {
  transition: 300ms;
  background: #ff7934;
  color: #fff;
  border: none;
  cursor: pointer;
}

.wp_add_session {
  transition: 300ms;
  border: 1px dashed #ff7934;
  background: #e7f0e4;
}

.wp_item_session,
.wp_add_session {
  min-height: 86px;
}

.bg-www {
  border: 2px solid #ff7934;
  background-color: #ff79342b;
}

.bg-device {
  border: 2px solid #ff7934;
}

.tm_whatsapp {
  background-image: url(https://static.whatsapp.net/rsrc.php/v1/yQ/r/dPFl9fRFF9u.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto 265px;
  /* min-height: 250px;
    max-height: 250px; */
  overflow-x: auto;
}
</style>
